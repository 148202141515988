<template>
  <router-link v-if="!external" :to="to" class="link" :class="{ active: isActive }">
    <i class="icon" :class="icon" />
    <transition name="fade">
      <span v-if="!collapsed">
        <slot />
      </span>
    </transition>
  </router-link>

  <a v-else class="link" :class="{ active: isActive }" :href="to" target="_blank">
    <i class="icon" :class="icon" />
    <span v-if="!collapsed">
      <slot />
    </span>
  </a>
</template>

<script setup>
import { computed } from "vue";
import { useRoute } from "vue-router";
import { collapsed } from "./state";

const props = defineProps({
  to: { type: String, required: true },
  icon: { type: String, required: true },
  external: { type: Boolean, default: false },
});

const route = useRoute();
const isActive = computed(() => route.path === props.to);
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  user-select: none;
  margin: 0.1em 0;
  padding: 0.4em;
  border-radius: 0.25em;
  height: 3em;
  color: white;
  text-decoration: none;
  white-space: normal;
}
.link:hover {
  background-color: var(--sidebar-item-hover);
}
.link.active {
  background-color: var(--sidebar-item-active);
}
.link .icon {
  flex-shrink: 0;
  width: 25px;
  margin-right: 10px;
}
</style>
